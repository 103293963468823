import Swiper, { Navigation, Autoplay }  from 'swiper';

const inspirationElement = document.querySelector('.s-team__inspiration-slider');

const teamSwiper = new Swiper('.s-team__team-slider', {
    modules: [Navigation, Autoplay],
    autoplay: {
        delay: 5000,
    },
    loop: true,

    // Responsive
    breakpoints: {
        960: {
            slidesPerView: 'auto',
            spaceBetween: 20
        }
    },
    slidesPerView: 1.2,
    spaceBetween: 20,

    // Navigation arrows
    navigation: {
        nextEl: '.team-button-next',
        prevEl: '.team-button-prev',
    }
});

if (inspirationElement) {
    const inspirationSwiper = new Swiper('.s-team__inspiration-slider', {
        modules: [Navigation, Autoplay],
        autoplay: {
            reverseDirection: true,
            delay: 5000,
        },
        loop: true,
        breakpoints: {
            960: {
                slidesPerView: 'auto',
                spaceBetween: 20,
            }
        },
        slidesPerView: 1.2,
        spaceBetween: 20,

        // Navigation arrows
        navigation: {
            nextEl: '.inspiration-button-next',
            prevEl: '.inspiration-button-prev',
        }
    });
}




