const section = document.querySelector('.s-contact-info')
const tabs = document.querySelectorAll('.s-contact-info__tab-links');
const tabContent = document.querySelectorAll('.s-contact-info__tab-content');

const selectLocation = (event) => {
    section.style.backgroundImage = `url(${event.target.dataset.img}), url(${event.target.dataset.fallbackimg})`;

    tabContent.forEach((content) => {
        if (content.dataset.name == event.target.dataset.name) {
            content.style.display = 'block';
        } else {
            content.style.display = 'none';
        }
    });

    tabs.forEach((button) => {
        if (button.dataset.name == event.target.dataset.name) {
            button.classList.add('active')
        } else {
            button.classList.remove('active')
        }
    });
}

tabs.forEach((button) => {
    button.addEventListener('click', selectLocation)
});

