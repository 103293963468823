import Swiper, { Navigation, Autoplay } from "swiper";

new Swiper(".s-gallery-block__images", {
    modules: [Navigation, Autoplay],
    autoplay: {
        delay: 5000,
    },
    loop: true,
    centeredSlides: true,
    slidesPerView: 1.5,
    spaceBetween: 20,
});

new Swiper(".project__gallery__images", {
    modules: [Navigation, Autoplay],
    autoplay: {
        delay: 5000,
    },
    loop: true,
    centeredSlides: true,
    // Responsive
    breakpoints: {
        960: {
            slidesPerView: 6,
            spaceBetween: 20,
        },
    },
    slidesPerView: 3,
    spaceBetween: 20,

    // Navigation arrows
    navigation: {
        nextEl: ".projects-button-next",
        prevEl: ".projects-button-prev",
    },
});
