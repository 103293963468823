require("fslightbox");
const feather = require("feather-icons");

feather.replace({ "stroke-width": 1 });

window.onload = () => {
    // Sections
    require("./sections/header");
    require("./sections/news");
    require("./sections/team");
    require("./sections/contact-info");
    require("./sections/archive");
    require("./sections/gallery");
    require("./sections/tabs");

    // General
    require("./animations");
};
