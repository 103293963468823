import Swiper, { Autoplay } from 'swiper';

const header = document.querySelector('div.nav');

const button = document.querySelector('#nav-button');
const nav = document.querySelector('#nav-main');
const navItems = document.querySelectorAll('.js-nav-item');

const toggleNav = () => {
    nav.classList.toggle("active");
    button.classList.toggle("active");
};

navItems.forEach((elem) => {
    elem.addEventListener("click", toggleNav);
});

button.addEventListener('click', toggleNav)

const logoSwiper = new Swiper('.nav__slider', {
    modules: [Autoplay],
    direction: 'vertical',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    speed: 800,
    autoplay: {
        delay: 800,
    },
});

window.onscroll = () => {
    if (window.scrollY > 100) {
        header.classList.add('white');
        logoSwiper.autoplay.stop();
    } else {
        header.classList.remove('white');
        logoSwiper.autoplay.start();
    }
}

const handleLogoSection = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting && entry.target) {
        logoSwiper.slideTo(Number(entry.target.dataset.logoSection));
    }
  });
}

const initObserver = () => {
  const observer = new IntersectionObserver(handleLogoSection);

  const items = document.querySelectorAll('[data-logo-section]');

  for(var i in items) {
    if(!items.hasOwnProperty(i)) {
      continue;
    }
    observer.observe(items[i]);
  }
}

if (window.IntersectionObserver) {
  initObserver();
} else {
  console.log("IntersectionObserver not supported.");
}