const tabButtons = document.querySelectorAll(".tab-button");

tabButtons.forEach((button) => {
    button.addEventListener("click", () => {
        const tabNumber = button.dataset.tab;
        const id = button.dataset.id;

        const tabToShow = document.querySelector(
            `.s-tabs-${id} .tab-pane[data-tab="${tabNumber}"]`
        );
        const activeButton = document.querySelector(
            `.s-tabs-${id} .tab-button.active`
        );
        const activeTab = document.querySelector(
            `.s-tabs-${id} .tab-pane.active`
        );

        activeButton.classList.remove("active");
        button.classList.add("active");

        activeTab.classList.remove("active");
        tabToShow.classList.add("active");
    });
});
