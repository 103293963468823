const effects = ['.fade-up', '.slide-up', '.fade-down', '.fade-in'];
const effectSelector = effects.join(", ");

const toggleClass = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-inview');
    } else {
      entry.target.classList.remove('is-inview');
    }
  });
}

const initObserver = () => {
  const observer = new IntersectionObserver(toggleClass);
  // const halfWayObserver = new IntersectionObserver(toggleClass, {rootMargin: "0px 0px -50% 0px"})

  const items = document.querySelectorAll(effectSelector);

  for(var i in items) {
    if(!items.hasOwnProperty(i)) {
      continue;
    }
    observer.observe(items[i]);
  }

  // const delayedItems = document.querySelectorAll('[data-animate-delay]');
  // for(var i in items) {
  //   if(!delayedItems.hasOwnProperty(i)) {
  //     continue;
  //   }
  //   observer.observe(delayedItems[i]);
  // }
  
}

if (window.IntersectionObserver) {
  initObserver();
} else {
  console.log("IntersectionObserver not supported.");
}