const tabs = document.querySelectorAll('body.archive .category-menu__button');
const articles = document.querySelectorAll('article.c-card');
const noProjects = document.querySelector('.no-projects');

const selectCategory = (event) => {
    tabs.forEach((button) => {
        if (button == event.target) {
            button.classList.add('active')
            filterCards(button.dataset.category);

        } else {
            button.classList.remove('active')
        }

        if (event.target.dataset.count == 0) {
            noProjects.style.display = 'block';
        } else {
            noProjects.style.display = 'none';
        }
    });
}

const filterCards = (id) => {
    articles.forEach((article) => {
        const ids = article.dataset.categories.split(',');
        if (ids.includes(String(id))) {
            article.classList.remove('hidden');
        } else {
            article.classList.add('hidden');
        }
    })
}

tabs.forEach((button) => {
    button.addEventListener('click', selectCategory)
});

